// Exports.scss

$primary-color: rgba(0, 155, 192, 1);

.container {
    margin: 0;
    padding: 0 12px;
    margin-top: 24px;

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-left: 4px solid #212529 !important;
        border-bottom: 4px solid #212529 !important;
        gap: 1rem;
        padding: 24px 16px;

        .col {
            flex: 0 0 auto;
            width: 41.666667%; // Adjusts column width
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            svg {
                width: 3em;
                height: 3em;
                color: $primary-color;
                cursor: pointer; // Change cursor to pointer on hover
            }
        }
    }
}
