.attachments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-height: 200px;
    margin: auto;

    .view-attachments-btn {
        cursor: pointer;
        background-color: #039fc4;
        padding: 1rem 1.5rem;
        border: none;
        border-radius: 30px;
        color: #fff;
        box-shadow: 0 3px 6px #00000027;
        transition: 0.2s;
        width: fit-content;
        margin: 1rem auto;

        &:hover {
            box-shadow: none;
            background-color: #017c9b;
        }
    }

    .loading {
        margin: auto;
        img {
            margin: 1rem auto;
        }
    }

    .attachments-output {
        display: none;
        margin: 1rem 0;
    }

    .output-row {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .title {
        border-bottom: 1px solid #017c9b;
        padding: 0.5rem 0;
        width: 100%;
        text-align: center;
    }

    .attachment-table {
        thead {
            text-align: center;

            th {
                padding: 0.5rem 1rem;
            }
        }

        tbody {
            background-color: #039fc4;
            border: 1px solid #017c9b;
            padding: 1rem;
            text-align: center;
            tr {
                padding: 0.9rem;
                td:nth-child(1) {
                    border-radius: 30px;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    padding: 0.5rem 1rem;
                }

                td:nth-child(2) {
                    border-radius: 30px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    padding: 0.5rem 1rem;
                }
            }
        }
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }
}
