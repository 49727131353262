@import "../../breakpoints.scss";

.historyCover {
    .container {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0; // equivalent to py-2 in Bootstrap
        border-bottom: 1px solid;
        cursor: pointer;
    }
    .data {
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 155, 192, 0.3);
            padding: 8px;
        }
    }
    .activeData {
        background-color: rgba(0, 155, 192, 1);
        border-radius: 4px;
        padding: 8px;
        span,
        p {
            color: white;
        }
    }
    h4 {
        font-size: 18px;
        font-weight: 900 !important;
    }
    .history {
        width: 50%;

        @media screen and (max-width: 769px) {
            width: 75%;
        }

        @media screen and (max-width: 769px) {
            width: 95%;
        }
    }
}
