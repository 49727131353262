.report-form {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: .3s;
  transition: .3s;
  -webkit-transform: translateY(-1000px);
          transform: translateY(-1000px);
  z-index: 3;
}

.report-form .mask {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #000000e8;
  z-index: -1;
  top: 0;
}

.report-form .close-btn {
  position: absolute;
  top: 70px;
  right: 150px;
  cursor: pointer;
}

.report-form .close-btn .bar {
  width: 35px;
  border: 2px solid #dfe1e6;
  border-radius: 30px;
  margin: 0.3rem 0;
  background: #dfe1e6;
}

.report-form .close-btn .bar-1 {
  -webkit-transform: rotateZ(45deg) translateX(5px);
          transform: rotateZ(45deg) translateX(5px);
}

.report-form .close-btn .bar-2 {
  -webkit-transform: rotateZ(-45deg) translateX(6px);
          transform: rotateZ(-45deg) translateX(6px);
}

.main-form {
  margin-top: 2rem;
}

.main-form .form-title {
  margin: 4rem 5rem;
  color: #dfe1e6;
  font-size: 27px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.main-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 2rem auto;
  top: 170px;
  left: 250px;
  width: 100%;
  max-width: 500px;
}

.main-form .form-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0.8rem 0;
  border-radius: 5px;
}

.main-form .form-input label {
  color: #dfe1e6;
}

.main-form .form-validate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin: 0.8rem 0;
}

.main-form .form-validate input {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.main-form .form-validate label {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0 0.3rem;
  color: #0329b6;
}

.main-form .form-validate label span {
  color: red;
}

.main-form input {
  outline: 1px dotted rgba(0, 0, 0, 0.007);
}

.main-form .report-title-input {
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  border: none;
}

.main-form .report-description-input {
  height: 60px;
  resize: none;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
}

.main-form .report-attachment-input {
  background-color: #dfe1e6;
  border-radius: 5px;
}

.main-form .submit-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.4rem 0.6rem;
  margin: 0.4rem 0;
  color: #031E84;
  cursor: pointer;
  font-size: 16px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  background-color: #0AEDC2;
  border: none;
  border-radius: 7px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #fff;
}

.main-form .submit-btn:hover {
  background-color: #05b695;
}

.main-form .disable {
  background-color: #8e8e8e;
  color: #b3b3b3;
  cursor: not-allowed;
}

.main-form .disable:hover {
  background-color: #8e8e8e;
}

.show-form {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
