.svg {
    width: 150px;
    margin: 0 auto;
}
@font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 300; /* Light */
    src: url("./assets/Font/graphik/Graphik-Light.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-style: italic;
    font-weight: 300; /* Light Italic */
    src: url("./assets/Font/graphik/Graphik-LightItalic.woff") format("woff");
}
@font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 400; /* Regular */
    src: url("./assets/Font/graphik/Graphik-Regular.woff") format("woff");
}
@font-face {
    font-family: "Graphik";
    font-style: italic;
    font-weight: 400; /* Regular Italic */
    src: url("./assets/Font/graphik//Graphik-RegularItalic.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 500; /* Medium */
    src: url("./assets/Font/graphik//Graphik-Medium.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-style: italic;
    font-weight: 500; /* Medium Italic */
    src: url("./assets/Font/graphik//Graphik-MediumItalic.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 600; /* SemiBold */
    src: url("./assets/Font/graphik/Graphik-Semibold.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-style: italic;
    font-weight: 600; /* SemiBold Italic */
    src: url("./assets/Font/graphik/Graphik-SemiboldItalic.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 700; /* Bold */
    src: url("./assets/Font/graphik/Graphik-Bold.woff") format("woff");
}

@font-face {
    font-family: "Graphik";
    font-style: italic;
    font-weight: 700; /* Bold Italic */
    src: url("./assets/Font/graphik/Graphik-BoldItalic.woff") format("woff");
}

.ReactModal__Content--after-open {
    text-align: center;
    border-radius: 10px !important;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(78, 78, 78, 0.75) !important;
}

.ant-modal-content {
    border-radius: 1.4rem;
}

.ant-modal-wrap {
    background-color: rgba(100, 100, 100, 0.75) !important;

    -webkit-overflow-scrolling: touch;
}

.modal-close {
    position: absolute;
    font-size: 45px;
    top: -10px;
    right: 8px;
    outline: 0;
    border: 0;
    color: #e24c4a;
    cursor: pointer;
    border-radius: 50%;
}

.danger {
    background-color: #e24c4a;
    color: #fff;
}

* {
    font-family: "Graphik", sans-serif;
}
