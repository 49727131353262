.scroll-button {
    position: fixed;
    bottom: 120px;
    right: 80px;
    background-color: #374cd0;
    color: #fff;
    z-index: 50;
    padding: 15px 20px;
    border-radius: 50%;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}
