/**
*
* Testing
*
**/

*,
p {
    padding: 0;
    margin: 0;
}

.landing-page {
    background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#1d1554),
        to(#1d1554)
    );
    background: linear-gradient(0deg, #1d1554 0%, #1d1554 100%);
}

.jumbotron {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    overflow: hidden;
}

.jumbotron .header-background {
    position: absolute;
    top: -520px;
    right: -280px;
}

@media (max-width: 359px) {
    .jumbotron .header-background {
        display: none;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .jumbotron .header-background {
        display: none;
    }
}

.nav-bar {
    z-index: 1;
}

.nav-bar ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
    list-style: none;
    margin: 1rem;
    padding: 0.5rem 1rem;
}

.btn {
    padding: 0.8rem 2.8rem;
    border-radius: 30px;
    background-color: #0aedc2;
    color: #1d1554;
    font-size: 18px;
    font-weight: 600;
    -webkit-box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
}

.get-started-btn {
    color: #000;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.get-started-btn:hover {
    color: #0aedc2;
    background-color: #1d1554;
}

.nav-item {
    padding: 0.5rem;
}

.nav-item img {
    width: 100%;
    max-width: 170px;
}

@media (max-width: 359px) {
    .nav-item img {
        max-width: 90px;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .nav-item img {
        max-width: 110px;
    }
}

.nav-item .get-started-btn {
    padding: 0.8rem 2.8rem;
    border-radius: 30px;
    background-color: #0aedc2;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    -webkit-box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

@media (max-width: 359px) {
    .nav-item .get-started-btn {
        display: none;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .nav-item .get-started-btn {
        display: none;
    }
}

.nav-item .get-started-btn:hover {
    color: #0aedc2;
    background-color: #2b11e1;
}

.sign-up {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    z-index: 1;
    padding: 1rem;
    margin: 0.8rem auto;
    width: 100%;
    max-width: 1100px;
}

@media (max-width: 359px) {
    .sign-up {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        text-align: center;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .sign-up {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        text-align: center;
    }
}

.sign-up-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: start;
    align-self: flex-start;
    width: 60%;
}

@media (max-width: 359px) {
    .sign-up-info {
        width: 100%;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .sign-up-info {
        width: 100%;
    }
}

.sign-up-info .title {
    color: #0aedc2;
    font-size: 21px;
    letter-spacing: 0.7px;
    line-height: 30px;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    text-transform: uppercase;
    width: 100%;
}

.sign-up-info .main-title {
    color: #fff;
    font-size: 60px;
    letter-spacing: 0;
    line-height: 72px;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    width: 100%;
}

@media (max-width: 359px) {
    .sign-up-info .main-title {
        font-size: 30px;
        line-height: 50px;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .sign-up-info .main-title {
        font-size: 45px;
        line-height: 50px;
    }
}

.sign-up-info .body {
    color: #ffffff;
    font-size: 21px;
    letter-spacing: 0.7px;
    line-height: 30px;
    padding: 0.5rem;
    margin: 1.5rem 0.5rem;
    width: 100%;
}

.sign-up-info .sign-up-btn {
    font-size: 21px;
    padding: 0.5rem 2rem;
    margin: 0.5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@media (max-width: 359px) {
    .sign-up-info .sign-up-btn {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .sign-up-info .sign-up-btn {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }
}

.illustration {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.illustration img {
    width: 100%;
    max-width: 593px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.testimony-companies-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 2rem auto;
    padding: 0 0.5rem;
    width: 100%;
    max-width: 1170px;
}

.testimony-companies-container .title {
    text-align: center;
    color: #ffffff;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 43px;
    padding: 1rem 0.5rem;
    margin: 3rem 0;
}

.testimony-companies {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin: 1rem auto;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 3.5rem 2.5rem;
    border-radius: 20px;
    background-color: #2b11e1;
    -webkit-box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    max-width: 1135px;
}

@media (max-width: 359px) {
    .testimony-companies {
        padding: 0.6rem;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .testimony-companies {
        padding: 0.6rem;
    }
}

.testimony-companies img {
    margin: auto;
    padding: 1.3rem;
    -webkit-box-flex: 10%;
    -ms-flex: 10%;
    flex: 10%;
}

@media (max-width: 359px) {
    .testimony-companies img {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        padding: 0.8rem;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .testimony-companies img {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        padding: 0.8rem;
    }
}

.features-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    background-color: #342c65;
    padding: 2rem 3rem;
}

@media (max-width: 359px) {
    .features-section {
        padding: 2rem 0;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .features-section {
        padding: 2rem 0;
    }
}

.features-section .get-started-btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding: 0.5rem 2rem;
    margin: 4rem auto;
}

@media (max-width: 359px) {
    .features-section .get-started-btn {
        width: 100%;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features-section .get-started-btn {
        width: 100%;
    }
}

.features-section-title {
    text-align: center;
    padding: 2rem 0;
    margin: 0.5rem auto;
    width: 50%;
    color: #0aedc2;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 58px;
}

@media (max-width: 359px) {
    .features-section-title {
        font-size: 28px;
        width: 100%;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .features-section-title {
        font-size: 35px;
        width: 100%;
    }
}

.features {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    margin: 1rem auto;
    width: 100%;
}

@media (max-width: 359px) {
    .features {
        padding: 0.5rem;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .features {
        padding: 0.5rem;
    }
}

.features .feature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 50%;
    flex: 0 50%;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 2rem;
    margin: 0.7rem;
    width: 100%;
    max-width: 523px;
    height: 100%;
    max-height: 745px;
}

@media (max-width: 359px) {
    .features .feature {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        height: auto;
        max-height: -webkit-max-content;
        max-height: -moz-max-content;
        max-height: max-content;
        margin: 0.7rem 0;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .features .feature {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        height: auto;
        max-height: -webkit-max-content;
        max-height: -moz-max-content;
        max-height: max-content;
    }
}

.features .feature-description {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    margin: 4rem 2rem 1rem 2rem;
}

@media (max-width: 359px) {
    .features .feature-description {
        margin: 2rem 0 0 0;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .features .feature-description {
        margin: 2rem 0 0 0;
    }
}

.features .feature-icon {
    position: absolute;
    right: 50px;
    top: 40px;
}

.features .feature-details {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.6px;
    line-height: 30px;
    width: 85%;
    margin: 3rem 0;
}

@media (max-width: 359px) {
    .features .feature-details {
        width: 100%;
        font-size: 14.5px;
        margin: 1.5rem 0;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .features .feature-details {
        width: 100%;
        font-size: 16px;
        margin: 1.5rem 0;
    }
}

.features .feature-img {
    width: 100%;
    max-width: 400px;
    margin: 2rem auto 1rem auto;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

@media (max-width: 359px) {
    .features .feature-img {
        margin: 1rem auto;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .features .feature-img {
        margin: 1rem auto;
    }
}

.features .feature-img img {
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 280px;
}

.features .visualise-feature {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #0aedc2;
    border-radius: 20px;
    background-color: #1d1554;
}

.features .visualise-feature .feature-title {
    color: #0aedc2;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
    width: 70%;
}

@media (max-width: 359px) {
    .features .visualise-feature .feature-title {
        width: 80%;
        font-size: 18px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .visualise-feature .feature-title {
        width: 80%;
        font-size: 20px;
    }
}

.features .visualise-feature .feature-icon {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom: 1px solid #0aedc2;
    border-left: 1px solid #0aedc2;
    padding: 3rem;
    border-bottom-left-radius: 110px;
}

@media (max-width: 359px) {
    .features .visualise-feature .feature-icon {
        padding: 1.5rem;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .visualise-feature .feature-icon {
        padding: 2rem;
    }
}

.features .extract-feature {
    border: 1px solid #f04f45;
    border-radius: 20px;
    background-color: #1d1554;
}

.features .extract-feature .feature-title {
    color: #f04f45;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 29px;
    width: 70%;
}

@media (max-width: 359px) {
    .features .extract-feature .feature-title {
        width: 80%;
        font-size: 18px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .extract-feature .feature-title {
        width: 80%;
        font-size: 20px;
    }
}

.features .extract-feature .feature-icon {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom: 1px solid #f04f45;
    border-left: 1px solid #f04f45;
    padding: 3rem;
    border-bottom-left-radius: 110px;
}

@media (max-width: 359px) {
    .features .extract-feature .feature-icon {
        padding: 1.5rem;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .extract-feature .feature-icon {
        padding: 2rem;
    }
}

.features .clone-feature {
    border: 1px solid #ff7b38;
    border-radius: 20px;
    background-color: #1d1554;
    -ms-grid-row: 2;
    grid-row: 2;
    -ms-grid-column: 1;
    grid-column: 1;
}

.features .clone-feature .feature-title {
    color: #ff7b38;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 29px;
    width: 60%;
}

@media (max-width: 359px) {
    .features .clone-feature .feature-title {
        width: 80%;
        font-size: 18px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .clone-feature .feature-title {
        width: 80%;
        font-size: 20px;
    }
}

.features .clone-feature .feature-icon {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom: 1px solid #ff7b38;
    border-left: 1px solid #ff7b38;
    padding: 3rem;
    border-bottom-left-radius: 110px;
}

@media (max-width: 359px) {
    .features .clone-feature .feature-icon {
        padding: 1.5rem;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .clone-feature .feature-icon {
        padding: 2rem;
    }
}

.features .migrate-feature {
    border: 1px solid #fdd800;
    border-radius: 20px;
    background-color: #1d1554;
    -ms-grid-row: 2;
    grid-row: 2;
    -ms-grid-column: 2;
    grid-column: 2;
}

.features .migrate-feature .feature-title {
    color: #ff7b38;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 29px;
    width: 40%;
}

@media (max-width: 359px) {
    .features .migrate-feature .feature-title {
        width: 70%;
        font-size: 18px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .migrate-feature .feature-title {
        width: 80%;
        font-size: 20px;
    }
}

.features .migrate-feature .feature-icon {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom: 1px solid #fdd800;
    border-left: 1px solid #fdd800;
    padding: 3rem;
    border-bottom-left-radius: 110px;
}

@media (max-width: 359px) {
    .features .migrate-feature .feature-icon {
        padding: 1.5rem;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .features .migrate-feature .feature-icon {
        padding: 2rem;
    }
}

.more-features-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1100px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-item-align: center;
    align-self: center;
    margin: 3rem auto;
    padding: 2rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

@media (max-width: 359px) {
    .more-features-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 0.6rem;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .more-features-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 0.7rem;
    }
}

.more-features {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: center;
    align-self: center;
    width: 45%;
}

@media (max-width: 359px) {
    .more-features {
        width: 100%;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .more-features {
        width: 100%;
    }
}

.more-features .feature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    max-width: 403px;
    margin: 0.5rem 0;
}

@media (max-width: 359px) {
    .more-features .feature {
        width: 100%;
        max-width: 90%;
        margin: 0.5rem auto;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .more-features .feature {
        width: 100%;
        max-width: 90%;
        margin: 0.5rem auto;
    }
}

.more-features .feature-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background-color: #2b11e1;
    padding: 1.5rem 1rem;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.more-features .feature-header:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    -webkit-transform: 0.3s;
    transform: 0.3s;
}

.more-features .feature-header .feature-icon {
    width: 100%;
    max-width: 30px;
}

.more-features .feature-header h3 {
    color: #0aedc2;
    font-size: 18px;
    width: 70%;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 22px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

@media (max-width: 359px) {
    .more-features .feature-header h3 {
        width: 100%;
        font-size: 14px;
        margin: 0 0.3em 0.3em 0.3em;
        line-height: 18px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .more-features .feature-header h3 {
        width: 100%;
        font-size: 14px;
        margin: 0 0.3em 0.3em 0.3em;
        line-height: 18px;
    }
}

.more-features .feature-header .open-arrow {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.more-features .clicked {
    border-radius: 10px 10px 0 0;
}

.more-features .clicked .open-arrow {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.more-features .clicked:hover {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.more-features .feature-body {
    height: 0;
    color: #1d1554;
    background-color: #fff;
    font-size: 14px;
    letter-spacing: 0.47px;
    line-height: 24px;
    width: 100%;
    padding: 0;
    border-radius: 0 0 10px 10px;
    -webkit-transition: 0.1s;
    transition: 0.1s;
}

.more-features .open-feature-body {
    height: 100%;
    padding: 2.5rem 2.5rem 2rem 2.5rem;
    -webkit-transition: 0.1s;
    transition: 0.1s;
}

.more-features .collaborative-feature .feature-header {
    padding: 1rem;
}

.more-features-img {
    width: 55%;
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin: 0.5rem;
}

@media (max-width: 359px) {
    .more-features-img {
        width: 100%;
        margin: 1.5rem 0;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .more-features-img {
        width: 100%;
        margin: 1.5rem 0;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }
}

.more-features-img img {
    width: 100%;
    max-width: 655px;
}

@media (max-width: 359px) {
    .more-features-img img {
        max-width: 390px;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .more-features-img img {
        max-width: 390px;
    }
}

.pricing-and-contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    background-color: #342c65;
    padding: 2rem 1rem;
    width: 100%;
}

@media (max-width: 359px) {
    .pricing-and-contact {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media (min-width: 361px) and (max-width: 1124px) {
    .pricing-and-contact {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.pricing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
    max-width: 528px;
}

@media (max-width: 359px) {
    .pricing {
        width: 100%;
    }
}

@media (min-width: 361px) and (max-width: 974px) {
    .pricing {
        width: 100%;
    }
}

.prices {
    border-radius: 20px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
}

.prices > .price {
    border-bottom: 1px solid #0aedc2;
}

.pricing-header,
.contact-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 1rem;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 1rem 0 0 0;
}

.pricing-header img,
.contact-header img {
    width: 100%;
    max-width: 60px;
    margin-right: 0.5rem;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

@media (max-width: 359px) {
    .pricing-header img,
    .contact-header img {
        max-width: 30px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .pricing-header img,
    .contact-header img {
        max-width: 40px;
    }
}

.pricing-header h3,
.contact-header h3 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto 0;
}

@media (max-width: 359px) {
    .pricing-header h3,
    .contact-header h3 {
        font-size: 20px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .pricing-header h3,
    .contact-header h3 {
        font-size: 22px;
    }
}

.price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 1rem 0;
}

@media (max-width: 359px) {
    .price {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .price {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
    }
}

.price-tag {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding: 1.5rem 3rem;
    color: #2b11e1;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 1.6px;
    line-height: 25px;
}

@media (max-width: 359px) {
    .price-tag {
        padding: 0.5rem;
        font-size: 30px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .price-tag {
        padding: 0.5rem 2rem;
        font-size: 33px;
    }
}

.price-details {
    padding: 0.5rem 5rem;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

@media (max-width: 359px) {
    .price-details {
        padding: 0.5rem 2rem;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .price-details {
        padding: 0.5rem 2rem;
    }
}

.price-details .users {
    color: #1d1554;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 25px;
}

.price-details .duration {
    color: #1d1554;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.6px;
    line-height: 25px;
}

.price-details .trial-duration {
    font-weight: 200;
    font-size: 14px;
}

.pricing-examples {
    text-align: center;
    -ms-flex-item-align: baseline;
    align-self: baseline;
    justify-self: flex-end;
    border-radius: 0 0 20px 20px;
    background-color: #0aedc2;
    -webkit-box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
}

.pricing-examples p {
    color: #2b11e1;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 19px;
    padding: 1rem 0.5rem;
    margin: 0;
}

@media (max-width: 359px) {
    .pricing-examples p {
        font-size: 14px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .pricing-examples p {
        font-size: 16px;
    }
}

.pricing-examples p a {
    color: #2b11e1;
}

.pricing-examples p a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    max-width: 528px;
}

.contact-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
}

.contact-details {
    padding: 0 2rem;
    width: 90%;
}

@media (max-width: 359px) {
    .contact-details {
        width: 100%;
        padding: 0 1rem;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .contact-details {
        width: 100%;
        padding: 0 1rem;
    }
}

.contact-details .contact-title {
    color: #2b11e1;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 25px;
    padding: 1rem 0;
    margin: 1rem 0;
}

.contact-details .contact-body {
    color: #1d1554;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.6px;
    line-height: 25px;
    margin: 1rem 0;
    padding: 0.3rem 0;
}

@media (max-width: 359px) {
    .contact-details .contact-body {
        font-size: 14px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .contact-details .contact-body {
        font-size: 14px;
    }
}

.contact-details .mobile-number {
    color: #2b11e1;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 25px;
    padding: 1.5rem 0;
    margin: 1rem 0;
}

@media (max-width: 359px) {
    .contact-details .mobile-number {
        font-size: 1.42857143em;
        padding: 1rem 0;
        margin: 0;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .contact-details .mobile-number {
        font-size: 1.42857143em;
        padding: 1rem 0;
        margin: 0;
    }
}

.email-contact {
    width: 100%;
    text-align: center;
    -ms-flex-item-align: baseline;
    align-self: baseline;
    justify-self: flex-end;
    border-radius: 0 0 20px 20px;
    background-color: #2b11e1;
    -webkit-box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 45px 12px rgba(0, 0, 0, 0.09);
}

.email-contact p {
    color: #0aedc2;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 19px;
    padding: 1rem 0.5rem;
    margin: 0;
}

@media (max-width: 359px) {
    .email-contact p {
        font-size: 14px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .email-contact p {
        font-size: 16px;
    }
}

.email-contact p a {
    color: #0aedc2;
}

.email-contact p a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.get-started-section {
    padding: 4rem 0.5rem;
}

.get-started {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    background: url("../../assets/Homepage Assets/pull out section/background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 95%;
    height: auto;
    max-width: 1200px;
    margin: 1rem auto 4rem auto;
    padding: 5rem 0;
}

@media (max-width: 359px) {
    .get-started {
        width: 100%;
        height: auto;
        padding: 1rem;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started {
        width: 100%;
        height: auto;
        padding: 1rem;
    }
}

.get-started .particle-1 {
    position: absolute;
    top: 60%;
    right: 5%;
    -webkit-animation: blink;
    animation: blink;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}

@media (max-width: 359px) {
    .get-started .particle-1 {
        display: none;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started .particle-1 {
        display: none;
    }
}

.get-started .particle-2 {
    position: absolute;
    top: 10%;
    right: 15%;
    -webkit-animation: asteriod;
    animation: asteriod;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}

@media (max-width: 359px) {
    .get-started .particle-2 {
        -webkit-animation: none;
        animation: none;
        display: none;
    }
}

.get-started .particle-3 {
    position: absolute;
    top: 25%;
    left: 10%;
    -webkit-animation: asteriod;
    animation: asteriod;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-direction: reverse;
}

@media (max-width: 359px) {
    .get-started .particle-3 {
        -webkit-animation: none;
        animation: none;
        display: none;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started .particle-3 {
        display: none;
    }
}

.get-started .particle-4 {
    position: absolute;
    top: 50%;
    left: 20%;
    -webkit-animation: blink;
    animation: blink;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

@media (max-width: 359px) {
    .get-started .particle-4 {
        display: none;
    }
}

.get-started .particle-5 {
    position: absolute;
    top: 8%;
    left: 42%;
    -webkit-animation: asteriod;
    animation: asteriod;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

@media (max-width: 359px) {
    .get-started .particle-5 {
        -webkit-animation: none;
        animation: none;
        display: none;
    }
}

.get-started .particle-6 {
    position: absolute;
    top: 75%;
    left: 70%;
    -webkit-animation: asteriod;
    animation: asteriod;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    animation-direction: reverse;
}

@media (max-width: 359px) {
    .get-started .particle-6 {
        -webkit-animation: none;
        animation: none;
        display: none;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started .particle-6 {
        display: none;
    }
}

.get-started .particle-7 {
    position: absolute;
    top: 66%;
    left: 4%;
    -webkit-animation: blink;
    animation: blink;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@media (max-width: 359px) {
    .get-started .particle-7 {
        display: none;
    }
}

.get-started .particle-8 {
    position: absolute;
    top: 85%;
    left: 35%;
    -webkit-animation: blink;
    animation: blink;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}

@media (max-width: 359px) {
    .get-started .particle-8 {
        display: none;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started .particle-8 {
        display: none;
    }
}

.get-started-title {
    color: #f8f8fa;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 58px;
    text-align: center;
    width: 50%;
    margin: 0.5rem 0;
    padding: 0.5rem 0;
    z-index: 1;
}

@media (max-width: 359px) {
    .get-started-title {
        width: 100%;
        font-size: 20px;
        line-height: 35px;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started-title {
        width: 95%;
        font-size: 29px;
        line-height: 35px;
    }
}

.get-started-subtitle {
    color: #f8f8fa;
    font-size: 21px;
    letter-spacing: 0.7px;
    line-height: 30px;
    text-align: center;
    padding: 0.5rem 0;
    margin: 2rem 0;
    z-index: 1;
}

@media (max-width: 359px) {
    .get-started-subtitle {
        font-size: 14px;
        margin: 1rem 0;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started-subtitle {
        font-size: 16px;
        margin: 1rem 0;
    }
}

.get-started-btn {
    width: 100%;
    max-width: 250px;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.8px;
    line-height: 30px;
    text-align: center;
    margin: 2rem 0;
    padding: 1rem 0;
    z-index: 1;
}

@media (max-width: 359px) {
    .get-started-btn {
        font-size: 16px;
        width: 100%;
        margin: 1rem auto;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .get-started-btn {
        font-size: 16px;
        width: 100%;
        margin: 1rem auto;
    }
}

footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    padding: 1rem;
    border-radius: 50px 50px 0 0;
    background-color: #342c65;
}

.footer-components {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 2rem 0;
    margin: 2rem 0;
}

@media (max-width: 359px) {
    .footer-components {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        margin: 0;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .footer-components {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        margin: 0;
    }
}

.footer-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

@media (max-width: 359px) {
    .footer-main {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .footer-main {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.footer-logo {
    padding: 1rem;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin: 0 4rem 0 2rem;
}

.footer-logo img {
    width: 100%;
    max-width: 150px;
}

.footer-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    padding: 2rem 2.5rem;
}

@media (max-width: 359px) {
    .footer-list {
        padding: 0;
    }
}

@media (min-width: 361px) and (max-width: 767px) {
    .footer-list {
        padding: 0;
    }
}

.footer-list .footer-list-header {
    margin: 1rem 0;
    -ms-grid-column-align: center;
    justify-self: center;
    text-transform: uppercase;
    color: #0aedc2;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
}

.footer-list ul {
    list-style: none;
    padding-left: 0;
}

.footer-list .footer-item {
    margin: 0.1rem 0;
}

.footer-list .footer-item a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
    padding: 0.1rem 0;
}

.support {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 3rem 0.5rem;
}

@media (max-width: 359px) {
    .support {
        padding: 1rem 0;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .support {
        padding: 1rem 0;
    }
}

.support p {
    margin: 0 0.5rem;
    color: #f8f8fa;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.support img {
    width: 100%;
    max-width: 100px;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

@media (max-width: 359px) {
    .support img {
        max-width: 60px;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .support img {
        max-width: 60px;
    }
}

.copyright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 1rem;
    padding: 1rem;
}

@media (max-width: 359px) {
    .copyright {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
        padding: 0;
        text-align: center;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .copyright {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
        padding: 0;
        text-align: center;
    }
}

.copyright p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    padding: 0 1rem;
}

@media (max-width: 359px) {
    .copyright p {
        padding: 1rem;
    }
}

@media (min-width: 361px) and (max-width: 874px) {
    .copyright p {
        padding: 1rem;
    }
}

.terms-and-conditions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    padding: 0 1rem;
}

.terms-and-conditions a {
    padding: 0 0.2rem;
    margin: 0 0.2rem;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
}

@-webkit-keyframes blink {
    0% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
    }
}

@keyframes blink {
    0% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
    }
}

@-webkit-keyframes asteriod {
    0% {
        opacity: 1;
        -webkit-transform: scale(0.5) translateX(60px);
        transform: scale(0.5) translateX(60px);
    }
    25% {
        -webkit-transform: scale(1) translateY(110px);
        transform: scale(1) translateY(110px);
    }
    50% {
        -webkit-transform: scale(1.5) translateZ(180px);
        transform: scale(1.5) translateZ(180px);
    }
    100% {
        opacity: 0;
    }
}

@keyframes asteriod {
    0% {
        opacity: 1;
        -webkit-transform: scale(0.5) translateX(60px);
        transform: scale(0.5) translateX(60px);
    }
    25% {
        -webkit-transform: scale(1) translateY(110px);
        transform: scale(1) translateY(110px);
    }
    50% {
        -webkit-transform: scale(1.5) translateZ(180px);
        transform: scale(1.5) translateZ(180px);
    }
    100% {
        opacity: 0;
    }
}
