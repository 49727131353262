.view-report-page {
  padding: 1rem;
  margin: 0.5rem;
}

.view-report-page .view-report {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.view-report-page .back-to-home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.3rem;
}

.view-report-page .back-to-home h3 {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin-bottom: 0;
}

.view-report-page .back-to-home img {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0 0.3rem;
}

.view-report-page .view-report-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 1rem auto;
}

.view-report-page .view-report-details .attachment {
  max-width: 350px;
  margin: 0.6rem 0.8rem;
}

.view-report-page .view-report-details .attachment img {
  width: 100%;
  border-radius: 20px;
}

.view-report-page .view-report-details .view-report-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
}

.view-report-page .view-report-details .view-report-info .report-title {
  margin-bottom: 0;
  font-size: 20px;
}

.view-report-page .view-report-details .view-report-info .report-description {
  max-width: 700px;
  padding: 0.2rem 0;
}

.view-report-page .view-report-details .view-report-info .votes-comments-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0.2rem;
  margin: 0.2rem 0;
}

.view-report-page .view-report-details .view-report-info .report-votes,
.view-report-page .view-report-details .view-report-info .report-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0.2rem;
  vertical-align: bottom;
}

.view-report-page .view-report-details .view-report-info .report-votes .votes-icon,
.view-report-page .view-report-details .view-report-info .report-votes .comments-icon,
.view-report-page .view-report-details .view-report-info .report-comments .votes-icon,
.view-report-page .view-report-details .view-report-info .report-comments .comments-icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  padding: 0.2rem;
}

.view-report-page .view-report-details .view-report-info .report-votes .votes-count,
.view-report-page .view-report-details .view-report-info .report-votes .comments-count,
.view-report-page .view-report-details .view-report-info .report-comments .votes-count,
.view-report-page .view-report-details .view-report-info .report-comments .comments-count {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0.3rem 0 0 0;
  padding: 0.1rem;
  font-size: 16px;
}

.view-report-page .voting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: center;
      align-self: center;
  margin-left: 50%;
}

.view-report-page .voting .upvote,
.view-report-page .voting .downvote {
  padding: 0.3rem;
  margin: 0.2rem;
  max-width: 60px;
  cursor: pointer;
}

.view-report-page .voting .upvote img,
.view-report-page .voting .downvote img {
  width: 100%;
}

.view-report-page .voting .upvote img:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 1s;
  transition: 1s;
}

.view-report-page .voting .downvote img:hover {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  -webkit-transition: 1s;
  transition: 1s;
}

.view-report-page .comments-section {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 5rem auto 3rem auto;
  max-width: 580px;
}

.view-report-page .comments-section .comments-section-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 20px;
  margin-bottom: 1rem;
}

.view-report-page .comments-section .comments-section-title span {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  color: rgba(95, 95, 95, 0.651);
  font-size: 14px;
  margin: 0.2rem 0.2rem;
}

.view-report-page .comments-section .comments {
  overflow-y: scroll;
  height: 150px;
  padding: 0.5rem 1rem;
}

.view-report-page .comments-section .comments::-webkit-scrollbar {
  width: 12px;
}

.view-report-page .comments-section .comments::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.048);
          box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.048);
  border-radius: 12px;
}

.view-report-page .comments-section .comments::-webkit-scrollbar-thumb {
  background-color: #818181cb;
  border-radius: 12px;
}

.view-report-page .comments-section .comments .comment .comment-owner {
  font-size: 13px;
}

.view-report-page .comments-section .add-comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.127);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.127);
  border-radius: 30px;
}

.view-report-page .comments-section .add-comment .comment-input {
  border: none;
  width: 80%;
  border-radius: 30px 0 0 30px;
  padding: 0.3rem 2rem;
  margin: 0.5rem 0;
  font-size: 16px;
  outline: 1px dotted rgba(0, 0, 0, 0.007);
}

.view-report-page .comments-section .add-comment .add-comment-btn {
  border-radius: 0 30px 30px 0;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  padding: 1rem 0.65rem;
  border: none;
  cursor: pointer;
  background-color: #0AEDC2;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #031E84;
}

.view-report-page .comments-section .add-comment .add-comment-btn:hover {
  background-color: #05b695;
  -webkit-box-shadow: none;
          box-shadow: none;
}
