.jira-software-counts-body {
    width: 100%;

    p {
        color: rgba(16, 16, 16, 1);
    }
    .mt-5 {
        margin-top: 48px;
    }
}

.btn {
    background: rgba(0, 155, 192, 1);

    &:active,
    &:focus {
        background: rgba(0, 155, 192, 1);
    }
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-content: center;
}
