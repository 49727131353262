.search-form {
  display: none;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.search-form ul {
  padding-left: 0;
}

.search-form .search-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  padding: 0.2rem 0.4rem;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.127);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.127);
  border-radius: 30px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.search-form .search-input input {
  border: none;
  border-radius: 30px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0.6rem;
  outline: 1px dotted rgba(0, 0, 0, 0.007);
  font-size: 17px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.search-form .search-input .search-btn {
  border: none;
  border-radius: 30px;
  width: 100%;
  padding: 0.35rem;
}

.search-form .search-input .search-btn img {
  max-width: 37px;
  padding: 0.1rem;
}

.search-form .enable-search-view {
  border-radius: 30px 30px 0 0;
}

.search-form .disable-search-view {
  border-radius: 30px;
}

.search-form .search-results {
  position: absolute;
  top: 54px;
  background: #fffffff0;
  width: 100%;
  max-width: 273px;
  border-radius: 0 0 8px 8px;
  list-style: none;
  margin-bottom: 0;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.127);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.127);
  z-index: 5;
}

.search-form .search-results li {
  margin: 0.4rem 0;
  padding: 0.3rem;
  color: #031E84;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}

.search-form .search-results li:hover {
  background: #05b695;
}

.show-search {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
