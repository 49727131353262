body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto",
        "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    height: 100%;
    width: 100%;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    background-color: #0e1923 !important;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
