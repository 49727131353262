*,
body,
html {
  margin: 0;
  padding: 0;
}

.visualize-community-homepage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.visualize-community-homepage .visualize-community-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0.6rem;
}

.visualize-community-homepage .visualize-community-header .desktop-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 1rem 0;
}

.visualize-community-homepage .visualize-community-header .desktop-menu .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.visualize-community-homepage .visualize-community-header .desktop-menu .logo img {
  width: 100%;
  max-width: 45px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.visualize-community-homepage .visualize-community-header .desktop-menu .logo .logo-name {
  color: #031E84;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0 0.4rem 0 0.4rem;
  font-size: 18px;
}

.visualize-community-homepage .visualize-community-header .desktop-menu nav {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.visualize-community-homepage .visualize-community-header .desktop-menu nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  margin-bottom: 0;
}

.visualize-community-homepage .visualize-community-header .desktop-menu nav .menu-item {
  list-style: none;
  padding: 0.4rem 0.6rem;
  margin: 0 1rem;
  color: #031E84;
  cursor: pointer;
  font-size: 16px;
}

.visualize-community-homepage .visualize-community-header .desktop-menu nav .cta {
  background-color: #0AEDC2;
  border-radius: 7px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #031E84;
}

.visualize-community-homepage .visualize-community-header .desktop-menu nav .cta:hover {
  background-color: #05b695;
}

.visualize-community-homepage .sorting {
  display: none;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 200px;
  margin: 0 3rem;
  padding: 0.3rem;
}

.visualize-community-homepage .sorting .sorting-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-decoration: none;
  cursor: pointer;
  padding: 0.3rem;
}

.visualize-community-homepage .sorting .sorting-title h4 {
  margin: 0 0.3rem;
}

.visualize-community-homepage .sorting .sorting-title img {
  max-width: 20px;
}

.visualize-community-homepage .sorting .sorting-options {
  display: none;
  position: absolute;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style: none;
  max-width: 120px;
  background-color: #fff;
  padding-left: 0;
  top: 35px;
  z-index: 2;
}

.visualize-community-homepage .sorting .sorting-option {
  padding: 0.3rem 0.7rem;
  color: #031E84;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  cursor: pointer;
}

.visualize-community-homepage .sorting .sorting-option:hover {
  background-color: #0AEDC2;
}

.visualize-community-homepage .show-sorting {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.visualize-community-homepage .show {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.visualize-community-homepage .report-feed-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  height: auto;
}

.visualize-community-homepage .report-feed-section .reports {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 1rem;
  width: 100%;
  max-width: 850px;
}

.visualize-community-homepage .report-feed-section .feed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 1rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 30px;
  max-width: 320px;
  width: 100%;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.082);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.082);
}

.visualize-community-homepage .report-feed-section .feed .feed-report {
  padding: 0.5rem;
  border-radius: 10px;
  background: #031f84;
  margin: 0.4rem;
  cursor: pointer;
  -webkit-transition: 0.15s;
  transition: 0.15s;
  max-width: 150px;
}

.visualize-community-homepage .report-feed-section .feed .feed-report:hover {
  background: #05b695;
}

.visualize-community-homepage .report-feed-section .feed .feed-report:hover h4 {
  color: #031f84;
}

.visualize-community-homepage .report-feed-section .feed .feed-report h4 {
  margin-bottom: 0;
  color: #05b695;
  -webkit-transition: 0.15s;
  transition: 0.15s;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
