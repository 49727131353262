.report {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.096);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.096);
  cursor: pointer;
  -webkit-transition: 0.15s;
  transition: 0.15s;
  margin: 0.8rem;
  width: 100%;
  max-width: 250px;
  border-radius: 15px;
  z-index: 0;
}

.report:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.report .report-img {
  width: 250px;
  height: 180px;
}

.report .report-img img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  border-radius: 12px 12px 0 0;
  max-width: 250px;
  max-height: 274.8px;
}

.report .report-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.report .report-info {
  margin: 0.6rem 0.2rem;
}

.report .report-info .report-title {
  padding: 0.3rem;
  margin-bottom: 0;
}

.report .report-info .report-owner {
  padding: 0 0.3rem;
  font-size: 14px;
  color: #7d7d7ddb;
}

.report .report-votes-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin: 0 1rem;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.report .report-votes,
.report .report-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0.2rem;
  vertical-align: bottom;
}

.report .report-votes .votes-icon,
.report .report-votes .comments-icon,
.report .report-comments .votes-icon,
.report .report-comments .comments-icon {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  padding: 0.2rem;
  max-width: 25px;
  width: 100%;
}

.report .report-votes .votes-count,
.report .report-votes .comments-count,
.report .report-comments .votes-count,
.report .report-comments .comments-count {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0.3rem 0 0 0;
  padding: 0.1rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.644);
}

.report .report-status {
  position: absolute;
  top: 11px;
  right: 2px;
  text-transform: uppercase;
  padding: 0.15rem;
  font-size: 8px;
  font-weight: bolder;
}

.report .pending {
  background-color: #EDC20A;
}

.report .reviewed {
  background-color: #a7ed0a;
}
