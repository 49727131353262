@import "../../../breakpoints.scss";

.homepage {
    display: flex;
    flex-direction: column;

    .result-page {
        position: absolute;
        top: 0px;
        bottom: 15px;
        right: 0px;
        left: 10px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 769px) {
            display: block;
            position: relative;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll;
            height: 100vh;
        }

        .data-section {
            flex: 1;
            height: 100%;
            // overflow-y: scroll;
            width: 60%;

            @media screen and (max-width: 769px) {
                height: 100%;
                width: 70vw;
            }

            @media screen and (max-width: 576px) {
                width: 100vw;
            }

            .banner-div {
                display: block;
                transition: 0.3s;
            }

            .hide {
                display: none;
            }
        }

        .right-end {
            width: 20vw;
            top: 0;
            right: 0;
            text-align: center;
            transition: all 1s ease-in;
            padding: 48px 24px 16px;

            h2 {
                font-size: 20px;
            }

            @media screen and (max-width: 769px) {
                height: 100vh;
                width: 30vw;
                z-index: 1;
                position: absolute;
            }

            @media screen and (max-width: 576px) {
                height: 100vh;
                width: 0px;
                z-index: 1;
                position: absolute;
                padding: 0px;
            }

            &.active {
                width: 90%;

                .close-menu-btn {
                    position: absolute;
                    top: 16px;
                    left: 15px;
                    width: 25px;
                    cursor: pointer;

                    .bar {
                        border: 2px solid #003850;
                        border-radius: 20px;
                        height: 2px;
                        width: 22px;
                        margin: auto;
                    }

                    .bar-1 {
                        transform: rotateZ(45deg) translateZ(24px);
                    }

                    .bar-3 {
                        transform: rotateZ(-45deg) translateX(3px)
                            translateY(-2px);
                    }
                }
            }
        }
    }
}

main {
    display: flex;
    flex-direction: row;
    background-color: #1e384f;

    @include media("<tablet") {
        padding: 0;
    }
}

.output {
    justify-self: center;
    width: 100%;
    // max-width: 900px;
    padding: 1.3rem;

    @include media("<phone") {
        width: 100%;
        margin: 0;
    }
}

// Themes
.software-theme,
.sdc-theme,
.scheme-theme {
    .logo {
        background: #fff;
    }

    .navigation {
        background: #fff;

        .nav-item {
            color: #0142ad;
        }

        .active {
            background: rgba(1, 66, 173, 0.25);
            border-left: 12px solid #0142ad;
            color: #0142ad;
        }
    }

    .output {
        background: #fff;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        display: none;
    }
}
